// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
  border: none;
  background-color: none;
  box-shadow: none;
  border-bottom: 1px solid #dbdbdb;
  border-radius: 0;
}

input:focus,
textarea:focus,
select:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/login/login.component.scss","webpack://./../../finsuro.mail%20(IVB%20Meeting%20Edition)/Frontend/src/app/auth/login/login.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,sBAAA;EACA,gBAAA;EACA,gCAAA;EACA,gBAAA;ACCJ;;ADEA;;;EAGI,gBAAA;EACA,wBAAA;ACCJ","sourcesContent":["input {\n    border: none;\n    background-color: none;\n    box-shadow: none;\n    border-bottom: 1px solid #dbdbdb;\n    border-radius: 0;\n}\n\ninput:focus,\ntextarea:focus,\nselect:focus {\n    box-shadow: none;\n    -webkit-box-shadow: none;\n}","input {\n  border: none;\n  background-color: none;\n  box-shadow: none;\n  border-bottom: 1px solid #dbdbdb;\n  border-radius: 0;\n}\n\ninput:focus,\ntextarea:focus,\nselect:focus {\n  box-shadow: none;\n  -webkit-box-shadow: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
