// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  color: #00a1e6;
  background-color: rgba(0, 161, 230, 0.5);
  z-index: "9999";
}

p.title {
  color: hsl(72, 100%, 50%);
}`, "",{"version":3,"sources":["webpack://./src/app/navigation/footer/footer.component.scss","webpack://./src/variables.scss","webpack://./../../finsuro.mail%20(IVB%20Meeting%20Edition)/Frontend/src/app/navigation/footer/footer.component.scss"],"names":[],"mappings":"AAEA;EACE,cCDK;EDGL,wCAAA;EACA,eAAA;AEFF;;AFKA;EACE,yBCPW;ACKb","sourcesContent":["@import '../../../variables';\n\nfooter {\n  color: $cyan;\n  // height: 250px;\n  background-color: rgba(0, 161, 230, 0.5);\n  z-index: \"9999\";\n}\n\np.title {\n  color: $lemongreen\n}\n","$white: #fff;\n$off-white: #f9f9f9;\n$cyan: #00a1e6;\n$lemongreen: hsl(72, 100%, 50%);\n$red: hsla(0, 100%, 64%, 1);\n\n$navbar-height: 5rem;\n$footer-min-height: 455px;\n\n@mixin light-shadow {\n  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),\n    0 5px 15px 0 rgba(0, 0, 0, 0.08);\n}\n\n@mixin sm-light-shadow {\n  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.11), 0 3px 7px 0 rgba(0, 0, 0, 0.08);\n}","footer {\n  color: #00a1e6;\n  background-color: rgba(0, 161, 230, 0.5);\n  z-index: \"9999\";\n}\n\np.title {\n  color: hsl(72, 100%, 50%);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
