import { Component, Input } from '@angular/core';
import { FormMessage } from './form-message.model';

@Component({
  selector: 'fnsr-form-message',
  templateUrl: './form-message.component.html'
})
export class FormMessageComponent {
  @Input() formMessage?: FormMessage;
}
