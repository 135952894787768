import { Injectable } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

import {
  faCheckCircle as fasCheckCircle, faCheck as fasCheck, faTimesCircle as fasTimesCircle, faPhoneSlash as fasPhoneSlash, faWrench as fasWrench, faTools as fasTools, faFileCode as fasFileCode, faCode as fasCode, faInfoCircle as fasInfoCircle, faEnvelope as fasEnvelope, faUser as fasUser, faPencilAlt as fasPencilAlt, faGraduationCap as fasGraduationCap, faFileAlt as fasFileAlt, faHome as fasHome, faInfo as fasInfo, faPhotoVideo as fasPhotoVideo, faLock as fasLock, faUserCircle as fasUserCircle, faIdBadge as fasIdBadge, faInfinity as fasInfinity, faStamp as fasStamp, faSearch as fasSearch, faHeart as fasHeart, faTrash as fasTrash, faTrashAlt as fasTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  faCheckCircle as falCheckCircle, faCheck as falCheck, faTimesCircle as falTimesCircle, faPhoneSlash as falPhoneSlash, faWrench as falWrench, faTools as falTools, faFileCode as falFileCode, faCode as falCode, faMailbox as falMailbox, faInfoCircle as falInfoCircle, faEnvelope as falEnvelope, faUser as falUser, faPencilAlt as falPencilAlt, faGraduationCap as falGraduationCap, faFileAlt as falFileAlt, faHome as falHome, faInfo as falInfo, faPhotoVideo as falPhotoVideo, faLock as falLock, faUserCircle as falUserCircle, faIdBadge as falIdBadge, faSignInAlt as falSignInAlt, faInfinity as falInfinity, faStamp as falStamp, faSearch as falSearch, faHeart as falHeart, faTrash as falTrash, faTrashAlt as falTrashAlt
} from '@fortawesome/pro-light-svg-icons';
import {
  faCheckCircle as fadCheckCircle,
  faCheck as fadCheck,
  faTimesCircle as fadTimesCircle,
  faUsers as fadUsers,
  faUserCrown as fadUserCrown,
  faUserPlus as fadUserPlus,
  faUserFriends as fadUserFriends,
  faUserLock as fadUserLock,
  faUserUnlock as fadUserUnlock,
  faMedal as fadMedal,
  faNewspaper as fadNewspaper,
  faQuestion as fadQuestion,
  faChalkboardTeacher as fadChalkboardTeacher,
  faPhoneSlash as fadPhoneSlash,
  faWrench as fadWrench,
  faTools as fadTools,
  faFileCode as fadFileCode,
  faCode as fadCode,
  faMailbox as fadMailbox,
  faInfoCircle as fadInfoCircle,
  faEnvelope as fadEnvelope,
  faScrewdriverWrench as fadScrewdriverWrench,
  faUser as fadUser,
  faPencilAlt as fadPencilAlt,
  faGraduationCap as fadGraduationCap,
  faFileAlt as fadFileAlt,
  faHome as fadHome, faInfo as fadInfo,
  faPhotoVideo as fadPhotoVideo,
  faLock as fadLock,
  faUserCircle as fadUserCircle,
  faIdBadge as fadIdBadge,
  faSignInAlt as fadSignInAlt,
  faInfinity as fadInfinity,
  faStamp as fadStamp,
  faClipboard as fadClipboard,
  faCopy as fadCopy,
  faSearch as fadSearch,
  faToolbox as fadToolbox,
  faBookUser as fadBookUser,
  faUserShield as fadUserShield,
  faSignIn as fadSignIn,
  faAddressCard as fadAddressCard,
  faHeart as fadHeart,
  faBuilding as fadBuilding,
  faExclamation as fadExlamation,
  faIcons as fadIcons,
  faCircleNotch as fadCircleNotch,
  faExclamationTriangle as fadExclamationTriangle,
  faSync as fadSync,
  faSyncAlt as fadSyncAlt,
  faCalendarAlt as fadCalendarAlt,
  faTrash as fadTrash,
  faTrashAlt as fadTrashAlt,
  faVenusMars as fadVenusMars,
  faPercent as fadPercent,
  faBarsProgress as fadBarsProgress,
  faSymbols as fadSymbols,
  faLockOpen as fadLockOpen,
  faLifeRing as fadLifeRing,
  faCommentQuestion as fadCommentQuestion,
  faListCheck as fadListCheck
} from '@fortawesome/pro-duotone-svg-icons';

import {
  // faTrash as farTrash,
  faTrashAlt as farTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
// import { faStackOverflow, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';

import {
  faTrashCan as fatTrashCan,
  faPlus as fatPlus,
  faPlusLarge as fatPlusLarge,
  faUserUnlock as fatUserUnlock,
  faLockOpen as fatLockOpen,
  faUser as fatUser,
  faVenusMars as fatVenusMars,
  faPercent as fatPercent,
  faBarsProgress as fatBarsProgress,
  faLocationDot as fatLocationDot,
  faFilePdf as fatFilePdf,
  faMedal as fatMedal,
  faAward as fatAward,
  faTrophy as fatTrophy,
  faVideo as fatVideo,
  faCartShopping as fatCartShopping,
  faPhotoFilm as fatPhotoFilm,
  faLifeRing as fatLifeRing,
  faCommentQuestion as fatCommentQuestion,
  faCircleNotch as fatCircleNotch,
  faEnvelope as fatEnvelope,
  faScrewdriverWrench as fatScrewdriverWrench
} from '@fortawesome/pro-thin-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class IconLibraryService {
  constructor(private library: FaIconLibrary) {}

  public loadIcons(): void {
    this.library.addIcons(
      farTrashAlt,
      fasCheckCircle, fasCheck, fasTimesCircle, fasPhoneSlash, fasWrench, fasTools, fasFileCode, fasCode, fasInfo, fasInfoCircle, fasEnvelope, fasUser, fasGraduationCap, fasFileAlt, fasPencilAlt, fasHome, fasInfo, fasPhotoVideo, fasLock, fasUserCircle, fasIdBadge, fasInfinity, fasStamp, fasSearch, fasHeart, fasTrash, fasTrashAlt,
      falCheckCircle, falCheck, falTimesCircle, falPhoneSlash, falWrench, falTools, falFileCode, falCode, falMailbox, falInfo, falInfoCircle, falEnvelope, falUser, falGraduationCap, falFileAlt, falPencilAlt, falHome, falInfo, falPhotoVideo, falLock, falUserCircle, falIdBadge, falSignInAlt, falInfinity, falStamp, falSearch, falHeart, falTrash, falTrashAlt,
      fadCheckCircle, fadCheck, fadTimesCircle, fadUsers, fadUserCrown, fadUserPlus, fadUserFriends, fadUserLock, fadUserUnlock, fadMedal, fadNewspaper, fadQuestion, fadChalkboardTeacher, fadPhoneSlash, fadWrench, fadTools, fadFileCode, fadCode, fadMailbox, fadInfo, fadInfoCircle, fadEnvelope, fadUser, fadGraduationCap, fadFileAlt, fadPencilAlt, fadHome, fadInfo, fadPhotoVideo, fadLock, fadUserCircle, fadIdBadge, fadSignInAlt, fadInfinity, fadStamp, fadClipboard, fadCopy, fadSearch, fadToolbox, fadBookUser, fadUserShield, fadSignIn, fadAddressCard, fadHeart, fadBuilding, fadExlamation, fadIcons, fadCircleNotch, fadExclamationTriangle, fadSync, fadSyncAlt, fadCalendarAlt, fadTrash, fadTrashAlt, fadVenusMars, fadPercent, fadBarsProgress, fadLockOpen, fadLifeRing, fadSymbols, fadCommentQuestion, fadListCheck, fadScrewdriverWrench,
      fatTrashCan, fatPlus, fatPlusLarge, fatUserUnlock, fatLockOpen, fatUser, fatVenusMars, fatPercent, fatBarsProgress, fatLocationDot, fatFilePdf, fatMedal, fatAward, fatTrophy, fatVideo, fatCartShopping, fatPhotoFilm, fatLifeRing, fatCommentQuestion, fatCircleNotch, fatEnvelope, fatScrewdriverWrench
    );
    
  }
}