import { Component } from "@angular/core";
import { interval, Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: 'fnsr-timer',
  templateUrl: './timer.component.html'
})
export class TimerComponent {

  constructor(public authService: AuthService) {
    this.timeLeft$ = interval(1000).pipe(
      map(_x => calcDateDiff(this.authService.loginExpiresAt())),
      shareReplay(1)
    );
  }
  public timeLeft$: Observable<timeComponents>;
}

function calcDateDiff(timerDate: Date): timeComponents {
  const tDate = timerDate.valueOf();

  const milliSecondsInASecond = 1000;
  const hoursInADay = 24;
  const minutesInAnHour = 60;
  const secondsInAMinute = 60;

  const timeDifference = tDate - Date.now();

  const days = Math.floor(
    timeDifference /
    (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay)
  );

  const hours = Math.floor(
    (timeDifference /
      (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
    hoursInADay
  );

  const minutes = Math.floor(
    (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
    secondsInAMinute
  );

  const seconds =
    Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;

  return { seconds, minutes, hours, days };
}

interface timeComponents {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
}