import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckService {
  constructor(public http: HttpClient) { }

  // TODO: Observable vom Response typisieren vgl. isUsernameAvailable
  isEmailAvailable(email: string): Observable<any> {
    return this.http.post<any>(`/api/tools/checks/email/available`, { email })
    .pipe(
      map(response => response.available),
      catchError((error: any) => {
        
        // console.error(error); // Loggen des Fehlers in der Konsole
    
        if (error.status === 409) { // Prüfen auf einen Conflict-Error (HTTP-Statuscode 409)
          return of({ emailConflict: true });
        }
  
        // Falls der Fehler ein anderer ist, geben Sie ein allgemeines Fehlerobjekt zurück
        return throwError({ emailError: true, error });
      })
    );
  }

  // TODO: Observable vom Response typisieren vgl. isUsernameAvailable
  isEmailApproved(email: string): Observable<any> {
    return this.http.post<any>(`/api/approvals/email`, { email })
    .pipe(
      map(response => response.approved),
      catchError((error: any) => {
        
        console.error(error); // Loggen des Fehlers in der Konsole
    
        if (error.status === 404) { // Prüfen auf einen Conflict-Error (HTTP-Statuscode 409)
          return of({ emailApprovalNotFound: true });
        }
  
        // Falls der Fehler ein anderer ist, geben Sie ein allgemeines Fehlerobjekt zurück
        return throwError({ emailApprovalError: true, error });
      })
    );
  }

  // TODO: Observable vom Response typisieren vgl. isUsernameAvailable
  checkEmailForBarmeniaDomain(email: string): Observable<any> {
    return this.http.post(`/api/tools/checks/email/barmenia`, { email });
  }

  // TODO: Observable vom Response typisieren vgl. isUsernameAvailable
  checkEmailForAllowedDomain(email: string): Observable<any> {
    return this.http.post(`/api/tools/checks/email/domain`, { email });
  }

  // TODO: Observable vom Response typisieren vgl. isUsernameAvailable
  isIntermediaryNumberAvailable(intermediaryNumber: string): Observable<any> {
    return this.http.post<any>(`/api/tools/checks/intermediarynumber/available`, { intermediaryNumber })
    .pipe(
      map(response => response.available),
      catchError((error: any) => {
        
        // console.error(error); // Loggen des Fehlers in der Konsole
    
        if (error.status === 409) { // Prüfen auf einen Conflict-Error (HTTP-Statuscode 409)
          return of({ intermediaryNumberConflict: true });
        }
  
        // Falls der Fehler ein anderer ist, geben Sie ein allgemeines Fehlerobjekt zurück
        return throwError({ intermediaryNumberError: true, error });
      })
    );
  }

  // TODO: Observable vom Response typisieren vgl. isUsernameAvailable
  isUsernameAvailable(username: string): Observable<any> {
    return this.http.post<any>(`/api/tools/checks/username/available`, { username })
      .pipe(
        map(response => response.available),
        catchError((error: any) => {
          
          // console.error(error); // Loggen des Fehlers in der Konsole
      
          if (error.status === 409) { // Prüfen auf einen Conflict-Error (HTTP-Statuscode 409)
            return of({ usernameConflict: true });
          }
    
          // Falls der Fehler ein anderer ist, geben Sie ein allgemeines Fehlerobjekt zurück
          return throwError({ usernameError: true, error });
        })
      );
  }

  // ToDo; Braucht es das noch?
  // private errorHandler(error: HttpErrorResponse): Observable<never> {
  //   console.error('Fehler aufgetreten!');
  //   return throwError(error);
  // }
}
