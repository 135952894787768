import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'fnsr-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  showBurgerMenu: boolean = false;

  closeBurgerMenu() {
    this.showBurgerMenu = false; 
  }

  public isEnvelopeAnimated = false;

  constructor(
    public authService: AuthService,
    public router: Router) {}

  public logout(): void {
    this.authService.logout();
  }
}
