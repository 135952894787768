// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ng-fa-icon.hover-red:hover {
  color: blue !important;
  stroke: blue !important;
}`, "",{"version":3,"sources":["webpack://./src/app/ui-design-library/icon/icon.component.scss","webpack://./../../finsuro.mail%20(IVB%20Meeting%20Edition)/Frontend/src/app/ui-design-library/icon/icon.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,uBAAA;ACCJ","sourcesContent":[".ng-fa-icon.hover-red:hover {\n    color: blue !important;\n    stroke: blue !important;\n}",".ng-fa-icon.hover-red:hover {\n  color: blue !important;\n  stroke: blue !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
