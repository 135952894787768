import { Component, Input, Optional } from '@angular/core';
import { FormGroup, FormGroupDirective, NgForm, ValidationErrors } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'fnsr-show-error',
  template: `
    <div *ngIf="currentErrorMessages" class="help is-danger">
      <div *ngFor="let errorMessage of currentErrorMessages">
        {{ errorMessage }}
      </div>
    </div>`,
  styleUrls: ['./show-error.component.scss']
})
export class ShowErrorComponent {
  @Input('path') path = '';
  @Input('text') displayName = '';

  private form!: FormGroup;
  public currentErrorMessages: string[] | null = null;

  constructor(
    @Optional() private ngForm: NgForm,
    @Optional() private formGroup: FormGroupDirective) {
  }

  ngOnInit() {
    this.form = this.ngForm ? this.ngForm.form : this.formGroup.form;
    this.setupControlValueChanges();
  }

  private setupControlValueChanges() {
    const control = this.form.get(this.path);
    if (control) {
      control.valueChanges.pipe(
        debounceTime(250)  // 250ms Verzögerung
      ).subscribe(() => {
        this.updateErrorMessages();
      });
    }
  }

  private updateErrorMessages() {
    const control = this.form.get(this.path);
    if (control) {
      this.currentErrorMessages = this.currentErrorMessages = control.errors ? this.getDisplayMessages(control.errors) : null;
    }
  }
  

  private getDisplayMessages(errors: ValidationErrors): string[] {
    return Object.entries(errors).map(([errorCode, error]): string => {
      console.log(errorCode, error)
      switch (errorCode) {
        case 'required':
          return `${this.displayName} wird benötigt`;
        case 'minlength':
          return `${this.displayName} muss mindestens ${error.requiredLength} Zeichen enthalten`;
        case 'maxlength':
          return `${this.displayName} darf maximal ${error.requiredLength} Zeichen enthalten`;

          case 'emailApproved':
          return `Freigabe existiert bereits`;

        case 'emailApprovalNotFound':
          return `Freigabe existiert nicht`;
        case 'emailApprovalError':
            return `Es trat ein unbekannter Fehler mit der Berechtigung^ auf`;
        case 'emailNotAvailable':
          return `E-Mail kann nicht vergeben werden.`;
        case 'usernameConflict':
          return `Benutzername kann nicht vergeben werden.`;
        case 'usernameInvalid':
          return `Bitte einen gültigen Benutzernamen (z.B. VornameNachname) eingeben`;  
        case 'usernameError':
          return `Es trat ein unbekannter Fehler mit dem Benutzernamen auf`;
        case 'intermediaryNumberConflict':
          return `Vermittlernummer kann nicht vergeben werden.`;
        case 'intermediaryNumberInvalid':
          return `Bitte eine gültige Vermittlernummer (z.B. 01234567) eingeben`;
        case 'intermediaryNumberError':
          return `Es trat ein unbekannter Fehler mit der Vermittlernummer auf`;  
        case 'noBarmeniaDomain':
          return `E-Mail ist keine @barmenia.de-Domain.`;
        case 'noAllowedDomain':
          return `E-Mail gehört nicht zu erlaubter Domain (@domain.de)`
        case 'emailConflict':
            return `E-Mail Adresse kann nicht vergeben werden.`;
        case 'emailInvalid':
            return `Bitte eine gültige E-Mail Adresse eingeben`;
        case 'emailError':
            return `Es trat ein unbekannter Fehler mit der E-Mail auf`;
        case 'invalidZip':
            return `Bitte eine gültige Postleitzahl (z.B. 76297) eingeben`;
        case 'invalidPrintPieceNumber':
          return `Bitte eine gültige Druckstück-Nr. (WB1312) eingeben`;
        case 'invalidPhoneNumber':
          return `Bitte eine gültige Telefonnummer (+4972175407340) eingeben`;
        
          // TODO: Diese waren vorgegeben und werden ggf. nicht gebraucht?!?
        case 'userNotFound':
          return `Der eingetragene Benutzer existiert nicht.`;
        default:
          return `${this.displayName} ist nicht valide`;
      }
    });
  }
}
