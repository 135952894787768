import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class RoleGuardService  {

  constructor(public authService: AuthService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // Get the role that this route is expected to have
    // which is set on the data property in the route
    // definitions
    const expectedRole = route.data['expectedRole'];

    if (!this.authService.isAuthenticated() || !this.authService.userHasRole(expectedRole)) {
      this.router.navigate(['']);
      return false;
    }
    return true;
  }
}
