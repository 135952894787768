import { Component, Input } from '@angular/core';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'fnsr-load',
  templateUrl: './load.component.html'
})
export class LoadComponent {
  @Input() size: SizeProp = '4x'
  @Input() text: string = 'wird geladen'

  // export type SizeProp =
  //   | "xs"
  //   | "lg"
  //   | "sm"
  //   | "1x"
  //   | "2x"
  //   | "3x"
  //   | "4x"
  //   | "5x"
  //   | "6x"
  //   | "7x"
  //   | "8x"
  //   | "9x"
  //   | "10x";

  constructor() { }

}
