import { Component } from '@angular/core';

@Component({
  selector: 'fnsr-page-not-found',
  template: `
  <div class="columns is-centered">
    <div class="column is-9">
        <h1 class="title">Fehler 404</h1>
        <h3 class="subtitle">Die Seite konnte nicht gefunden werden.</h3>

        <p>Eine Seite wurde angefordert, die es nicht (mehr) gibt. Bitte überprüfe eventuell die Schreibweise.</p>
    </div>
  </div>
  `
})
export class PageNotFoundComponent {

  constructor() { }

}
