import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fnsr-mail-preview',
  templateUrl: './mail-preview.component.html'
})
export class MailPreviewComponent implements OnInit {
  @Input() url = '';

  constructor() { }

  ngOnInit(): void {
  }
}
