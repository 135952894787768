import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';import { AppRoutingModule } from './app-routing.module';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

import { ReactiveFormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HttpClientXsrfModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';

import { AppComponent } from './app.component';
import { HeaderComponent} from './navigation/header/header.component';

import { InputErrorComponent } from './ui-design-library/input-error/input-error.component';

import { UserService } from './user/user.service';

import { FormMessageComponent } from './ui-design-library/form-message/form-message.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { RoleGuardService } from './auth/role-guard.service';

import { MailComponent } from './mail/mail.component';
import { PageNotFoundComponent } from './navigation/page-not-found/page-not-found.component';
import { AdditionPipe, SafePipe } from './pipes/addition.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MailPreviewComponent } from './mail/mail-preview/mail-preview.component';

import { MailMediaListComponent } from './mail/mail-media-list/mail-media-list.component';
import { MailFormComponent } from './mail/mail-form/mail-form.component';
import { ImprintComponent } from './policy/imprint/imprint.component';

import { HomeComponent } from './home/home.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { AuthService } from './auth/auth.service';

import { PrivacyComponent } from './policy/privacy/privacy.component';
import { FinsuroMailLogoComponent } from './logos/finsuro-mail-logo/finsuro-mail-logo.component';
import { ProgressComponent } from './ui-design-library/progress/progress.component';
import { IconComponent } from './ui-design-library/icon/icon.component';
import { LoadComponent } from './ui-design-library/load/load.component';
import { SyncComponent } from './ui-design-library/sync/sync.component';
import { TimerComponent } from './ui-design-library/timer/timer.component';
import { CheckComponent } from './checks/check.component';
import { CheckService } from './checks/check.service';

import { TokenInterceptorService as TokenInterceptor } from './auth/token-interceptor.service';
import { InputFieldComponent } from './ui-design-library/forms/input-field/input-field.component';
import { ShowErrorComponent } from './ui-design-library/forms/show-error/show-error.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoginComponent } from './auth/login/login.component';
import { IconLibraryService } from './ui-design-library/icon-library.service';

export function iconLibraryInitializer(iconLibrary: IconLibraryService): () => void {
  return () => iconLibrary.loadIcons();
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        LoginComponent,
        InputErrorComponent,
        FormMessageComponent,
        MailComponent,
        PageNotFoundComponent,
        AdditionPipe,
        SafePipe,
        MailPreviewComponent,
        MailMediaListComponent,
        ImprintComponent,
        HomeComponent,
        MailFormComponent,
        FooterComponent,
        PrivacyComponent,
        FinsuroMailLogoComponent,
        ProgressComponent,
        IconComponent,
        LoadComponent,
        SyncComponent,
        TimerComponent,
        CheckComponent,
        InputFieldComponent,
        ShowErrorComponent,
        LoginComponent,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'de' },
        AuthService,
        AuthGuardService,
        RoleGuardService,
        UserService,
        CheckService,
        IconLibraryService,
        {
          provide: APP_INITIALIZER,
          useFactory: iconLibraryInitializer,
          deps: [IconLibraryService],
          multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientXsrfModule,
        // HttpClientXsrfModule.withOptions({
        //   cookieName: 'XSRF-TOKEN',
        //   headerName: 'XSRF-TOKEN'
        // }),
        BrowserAnimationsModule,
        FontAwesomeModule,
        AppRoutingModule,
    ]
})
export class AppModule {
  constructor(iconLibrary: IconLibraryService) {
    iconLibrary.loadIcons();
    registerLocaleData(localeDe);
  }
}
