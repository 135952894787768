// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
input {
  border: none;
  outline: 0;
  background-color: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-bottom: 1px solid #dbdbdb;
  border-radius: 0;
}

input:focus,
textarea:focus,
select:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
}

/* Workaround für (graue) Textfarbe, da select und option keinen placeholder kennen */
/* Wert stammt aus ::webkit-input_placeholder */
select:required:invalid {
  color: rgba(54, 54, 54, 0.3);
}

span.required {
  color: red;
}`, "",{"version":3,"sources":["webpack://./../../finsuro.mail%20(IVB%20Meeting%20Edition)/Frontend/src/app/ui-design-library/forms/input-field/input-field.component.scss","webpack://./src/app/ui-design-library/forms/input-field/input-field.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;ACEhB;EACI,YAAA;EACA,UAAA;EACA,sBAAA;EACA,gBAAA;EACA,wBAAA;EACA,gCAAA;EACA,gBAAA;ADAJ;;ACGA;;;EAGI,gBAAA;EACA,wBAAA;ADAJ;;ACGA,qFAAA;AACA,+CAAA;AACA;EACI,4BAAA;ADAJ;;ACGA;EACI,UAAA;ADAJ","sourcesContent":["@charset \"UTF-8\";\ninput {\n  border: none;\n  outline: 0;\n  background-color: none;\n  box-shadow: none;\n  -webkit-box-shadow: none;\n  border-bottom: 1px solid #dbdbdb;\n  border-radius: 0;\n}\n\ninput:focus,\ntextarea:focus,\nselect:focus {\n  box-shadow: none;\n  -webkit-box-shadow: none;\n}\n\n/* Workaround für (graue) Textfarbe, da select und option keinen placeholder kennen */\n/* Wert stammt aus ::webkit-input_placeholder */\nselect:required:invalid {\n  color: rgba(54, 54, 54, 0.3);\n}\n\nspan.required {\n  color: red;\n}","@import '../../../../variables.scss';\n\ninput {\n    border: none;\n    outline: 0;\n    background-color: none;\n    box-shadow: none;\n    -webkit-box-shadow: none;\n    border-bottom: 1px solid #dbdbdb;\n    border-radius: 0;\n}\n\ninput:focus,\ntextarea:focus,\nselect:focus {\n    box-shadow: none;\n    -webkit-box-shadow: none;\n}\n\n/* Workaround für (graue) Textfarbe, da select und option keinen placeholder kennen */\n/* Wert stammt aus ::webkit-input_placeholder */\nselect:required:invalid {\n    color: rgba(54, 54, 54, 0.3);\n}\n\nspan.required {\n    color: red\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
