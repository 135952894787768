import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'fnsr-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent {
  @Input() formGroup!: FormGroup;
  @Input() id = '';
  @Input() type = 'text';
  @Input() text = '';;
  @Input() name = '';;
  @Input() value = '';
  @Input() path = '';;
  @Input() required: boolean = false;
}
