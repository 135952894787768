import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fnsr-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss']
})
export class InputErrorComponent implements OnInit {
  @Input('message') message = '';;

  constructor() {}

  ngOnInit() {}
}
