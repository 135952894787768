import { Injectable, NgModule } from "@angular/core";
import {
  PreloadAllModules,
  RouterModule,
  RouterStateSnapshot,
  Routes,
  TitleStrategy,
} from "@angular/router";

import { AuthGuardService as AuthGuard } from "./auth/auth-guard.service";
import { MailComponent } from "./mail/mail.component";
import { PageNotFoundComponent } from "./navigation/page-not-found/page-not-found.component";
import { ImprintComponent } from "./policy/imprint/imprint.component";
import { HomeComponent } from "./home/home.component";

import { PrivacyComponent } from "./policy/privacy/privacy.component";
import { Title } from "@angular/platform-browser";
import { LoginComponent } from "./auth/login/login.component";

@Injectable()
export class CustomTitleStrategy extends TitleStrategy {
  constructor(private title: Title) {
    super();
  }

  updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title) {
      this.title.setTitle(
        `${title} | finsuro.mail (IVB Meeting Edition) - app.finsuro.de`
      );
    } else {
      this.title.setTitle(
        "finsuro.mail (IVB Meeting Edition) - app.finsuro.mail"
      );
    }
  }
}

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    pathMatch: "full",
    title: "Startseite",
  },
  { path: "anmelden", component: LoginComponent, title: "Anmelden" },
  {
    path: "mail",
    component: MailComponent,
    canActivate: [AuthGuard],
    title: "Infomail versenden",
  },
  { path: "impressum", component: ImprintComponent, title: "Impressum" },
  { path: "datenschutz", component: PrivacyComponent, title: "Datenschutz" },
  {
    path: "**",
    component: PageNotFoundComponent,
    title: "Fehler 404 - Seite nicht gefunden",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: CustomTitleStrategy }],
})
export class AppRoutingModule {}
