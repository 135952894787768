// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main {
  padding: 20px 40px;
  min-height: calc(100vH - 5rem - 455px);
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss","webpack://./../../finsuro.mail%20(IVB%20Meeting%20Edition)/Frontend/src/app/app.component.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,sCAAA;ACDF","sourcesContent":["@import '../variables';\n\nmain {\n  padding: 20px 40px;\n  min-height: calc(100vH - $navbar-height - $footer-min-height);\n}","main {\n  padding: 20px 40px;\n  min-height: calc(100vH - 5rem - 455px);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
