import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { AuthService } from '../auth.service';
import { Credentials } from "../../user/credentials.model";
import { FormMessage } from 'src/app/ui-design-library/form-message/form-message.model';

@Component({
  selector: 'fnsr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form!: FormGroup;
  isLoading = false;
  formMessage?: FormMessage;

  constructor(
    public fb: FormBuilder,
    public authService: AuthService,
    public router: Router
  ) { }

  ngOnInit() {
    this.createForm();
  }

  private createForm(): void {
    this.form = this.fb.group({
      usernameOrEmail: new FormControl('', {
        validators: [Validators.required]
      }),
      password: new FormControl('', { validators: [Validators.required] })
    });
  }

  onSubmit(credentials: Credentials): void {
    this.isLoading = true;
    this.authService.login(credentials).subscribe(
      result => {
        console.log('loginResult');
        console.log(result);

        this.formMessage = {
          message: result.message,
          messageType: 'success'
        }
        this.authService.setUser(result);
        this.isLoading = false;
        this.router.navigate(['mail']);
      },
      (err: Error) => {
        this.formMessage = {
          message: err.message,
          messageType: 'error'
        };
        this.isLoading = false;
      }
    );
  }

}
