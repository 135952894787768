// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
  background: #00a1e6;
  color: hsl(72, 100%, 50%);
  margin-bottom: 20px;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
}

.navbar-brand img {
  max-height: 4rem;
}

img {
  max-height: 4rem;
}

span.icon {
  display: inline-flex;
  width: 2.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/navigation/header/header.component.scss","webpack://./src/variables.scss","webpack://./../../finsuro.mail%20(IVB%20Meeting%20Edition)/Frontend/src/app/navigation/header/header.component.scss"],"names":[],"mappings":"AAEA;EAGE,mBCHK;EDIL,yBCHW;EDIX,mBAAA;ECGA,+EAAA;ACLF;;AFMA;EACE,gBAAA;AEHF;;AFMA;EACE,gBAAA;AEHF;;AFQA;EACE,oBAAA;EACA,aAAA;AELF","sourcesContent":["@import '../../../variables';\n\nnav {\n  // background: -webkit-linear-gradient(left, #209cee 0%, #7d4792 100%);\n  // background: -webkit-linear-gradient(left, $cyan 0%, $lemongreen 100%);\n  background: $cyan;\n  color: $lemongreen;\n  margin-bottom: 20px;\n  @include light-shadow;\n}\n\n.navbar-brand img {\n  max-height: 4rem;\n}\n\nimg {\n  max-height: 4rem;\n}\n\n\n// Sorgt für den Abstand zwischen Font Awesome Icon und Menü\nspan.icon {\n  display: inline-flex;\n  width: 2.5rem;\n}\n","$white: #fff;\n$off-white: #f9f9f9;\n$cyan: #00a1e6;\n$lemongreen: hsl(72, 100%, 50%);\n$red: hsla(0, 100%, 64%, 1);\n\n$navbar-height: 5rem;\n$footer-min-height: 455px;\n\n@mixin light-shadow {\n  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),\n    0 5px 15px 0 rgba(0, 0, 0, 0.08);\n}\n\n@mixin sm-light-shadow {\n  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.11), 0 3px 7px 0 rgba(0, 0, 0, 0.08);\n}","nav {\n  background: #00a1e6;\n  color: hsl(72, 100%, 50%);\n  margin-bottom: 20px;\n  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);\n}\n\n.navbar-brand img {\n  max-height: 4rem;\n}\n\nimg {\n  max-height: 4rem;\n}\n\nspan.icon {\n  display: inline-flex;\n  width: 2.5rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
