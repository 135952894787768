import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../auth/auth.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MailService } from '../mail.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { InfoMail } from '../mail.model';
import { FormMessage } from 'src/app/ui-design-library/form-message/form-message.model';
import { emailValidator } from 'src/app/validators/custom-validators';
import { TemplateService } from 'src/app/templates/template.service';
import { Observable } from 'rxjs';
import { Category } from 'src/app/templates/template-category.model';

@Component({
  selector: 'fnsr-mail-form',
  templateUrl: './mail-form.component.html',
  styleUrls: ['./mail-form.component.scss']
})
export class MailFormComponent implements OnInit {
  categories$: Observable<Category[]>

  formMessage?: FormMessage;
  public sendMailResultForAdmin: any;

  apiParameter = {}
  apiParameterIsVisible = false;

  // selected = new FormControl(0);

  form!: FormGroup;

  isLoading = false;
  buttonText = 'Senden';
  buttonAPIText = 'einblenden';
  baseUrl = '/api';

  constructor(
    public fb: FormBuilder,
    public http: HttpClient,
    public authService: AuthService,
    private mailService: MailService,
    private templateService: TemplateService,
    private clipboard: Clipboard
  ) {
    const provider = this.authService.getProvider();
    this.categories$ = this.templateService.getTemplateListByProvider(provider);
  }
  
  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.form = this.fb.group({
      templateId: new FormControl('', [Validators.required]),
      formality: new FormControl('', [Validators.required]),
      salutation: new FormControl('', [Validators.required]),
      title: new FormControl(''),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', {
        validators: [
          Validators.required,
          emailValidator
        ]
      }),
      postscript: new FormControl({ value: this.authService.getPostscriptFromAgentPreferences(), disabled: false },
        Validators.maxLength(2000)
      ),
    })
  }

  getTotalTemplateCount(): number {
    let count = 0;
    this.categories$.subscribe(categories => {
      for (const category of categories) {
        count += category.templates.length;
      }
    });
    return count;
  }

  showApiParameter(infoMail: InfoMail) {
    this.apiParameter =
    {
      userData: {
        apiKey: this.authService.getUserInfo().apiKey
      },
      clientData: {
        templateId: infoMail.templateId,
        formality: infoMail.formality,
        salutation: infoMail.salutation,
        title: "",
        firstName: "",
        lastName: "",
        email: "",
      }
    }

    if (this.apiParameterIsVisible === false) {
      this.apiParameterIsVisible = true;
      this.buttonAPIText = 'ausblenden'
    } else {
      this.apiParameterIsVisible = false;
      this.buttonAPIText = 'ausblenden'
    }
  }

  copyApiUrl() {
    this.clipboard.copy('https://app.finsuro.de/api/authenticate/apikey');
  }

  copyApiParameter() {
    this.clipboard.copy(JSON.stringify(this.apiParameter, null, 4));
  }

  onSubmit(infoMail: InfoMail): void {
    this.isLoading = true;
    this.buttonText = 'Sende...';

    this.sendMailResultForAdmin = '';
    const agentId = this.authService.getUserId();
    infoMail.agentId = agentId;

    // this.http.post(`${this.baseUrl}/mails/sendmail`, infoMail)
    this.mailService.createMail(infoMail).subscribe(
      result => {
          this.isLoading = false;
            
          this.formMessage = {
            message: `Die E-Mail wurde erfolgreich an ${infoMail.email} versandt.`,
            messageType: 'success'
          };
          this.sendMailResultForAdmin = result;
        },
        (err: Error) => {
          this.isLoading = false;
          this.buttonText = 'Senden';
          
          console.log('err:', err)

          this.formMessage = {
            message: err.message,
            messageType: 'error'
          };
        },
        () => {
          this.isLoading = false;
          this.buttonText = 'Senden';
        }
      );
  }

  resetForm() {
    this.isLoading = false;
    this.buttonText = 'Senden';
    delete this.formMessage;
    this.sendMailResultForAdmin = '';
    this.form?.reset();
  }
}
