import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Category } from './template-category.model';
import { Template } from './template.model';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(
    public authService: AuthService,
    public http: HttpClient
  ) { }

  getTemplateListByProvider(provider: string): Observable<Category[]> {
    return this.http.get<Template[]>(`/api/templates/${provider}`)
      .pipe(
        retry(3),
        // extract categories
        map(templates => {
          const categories = templates.map(template => template.category)
            .filter((category, index, array) => array.indexOf(category) === index);
          return categories.map(category => { 
            return {
              category: category,
              templates: templates.filter(template => template.category === category)
            };
          });
        }),
        catchError(this.errorHandler)
      )
  }

  private errorHandler(error: HttpErrorResponse): Observable<any> {
    console.error('Fehler aufgetreten!');
    return throwError(error);
  }
}
