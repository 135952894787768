import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NewUser, UserData } from './../user/user.model';
import { Credentials } from "./../user/credentials.model";

export interface UserInfo {
  profileImageUrl: string;
  email: string;
  username: string;
  role: string;
  isLeader: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  profileImageUrl = '';
  username = '';
  isLeader = false;
  baseUrl = '/api/auth';

  constructor(public http: HttpClient, public router: Router) { }

  public isAuthenticated(): boolean {
    const expiresAt = localStorage.getItem('expiresAt');

    // If there's no expiresAt value, make
    // the user log in
    if (!expiresAt) {
      return false;
    }
    // Our indication as to whether the user is authenticated or not
    // is if they have an unexpired token. Return a boolean that compares
    // the current time with the token expiresAt time. The expiresAt value
    // needs to be parsed because it is stored as a string

    this.profileImageUrl = this.getUserInfo().profileImageUrl;
    this.username = this.getUserInfo().username;
    this.isLeader = this.getUserInfo().isLeader;

    return new Date().getTime() < parseInt(expiresAt);
  }

  public loginExpiresAt(): Date {
    const expiresAt = localStorage.getItem('expiresAt');

    if (!expiresAt) {
      return new Date(parseInt('0'));
    }

    return new Date(parseInt(expiresAt));
  }

  // TODO: Observable vom Response typisieren
  public login(credentials: Credentials): Observable<any> {
    // We're using the spread syntax to get the
    // individual properties off the supplied user
    // object onto a new object
    return this.http.post(`${this.baseUrl}/login`, { ...credentials });
  }

  private setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  private setUserInfo(userInfo: any): void {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }

  private setExpiresAt(expiresAt: number): void {
    // When we compare the current time against the expires at time
    // later, we do so using a JavaScript Date object. The Date
    // object deals with timestamps differently than how we have them
    // in the token, so we need to multiple by 1000
    localStorage.setItem('expiresAt', JSON.stringify(expiresAt * 1000));
  }

  public getUserInfo(): any {
    const userInfo = localStorage.getItem('userInfo')

    if (userInfo !== null) {
      return JSON.parse(userInfo)
    }
  }

  public getUsername(): any {
    const userInfo = this.getUserInfo();
    if (!userInfo) {
      return false;
    }
    return userInfo.username;
  }

  public getEmail(): any {
    const userInfo = this.getUserInfo();
    if (!userInfo) {
      return false;
    }
    return userInfo.email;
  }

  public getRole(): any {
    const userInfo = this.getUserInfo();
    if (!userInfo) {
      return false;
    } else if (userInfo.role === 'assistant') {
      return 'Assistenz'
    } else if (userInfo.role === 'user') {
      return 'Benutzer'
    } else if (userInfo.role === 'admin') {
      return 'Administrator'
    }
  }

  public getUserId(): any {
    const userInfo = this.getUserInfo();
    if (!userInfo) {
      return false;
    }
    return userInfo._id;
  }

  public getLoginCounter(): any {
    const userInfo = this.getUserInfo();
    if (!userInfo) {
      return false;
    }
    return userInfo.loginCounter;
  }

  public getProvider(): any {
    const userInfo = this.getUserInfo();
    if (!userInfo) {
      return false;
    }
    return userInfo.provider;
  }

  public getAgentPreferences(): string {
    const userInfo = localStorage.getItem('userInfo')
    if (!userInfo) {
      return '';
    }
    const preferences = JSON.parse(userInfo)

    return preferences;
  }

  public getPostscriptFromAgentPreferences(): string {
    const userInfo = localStorage.getItem('userInfo')
    if (!userInfo) {
      return '';
    }
    const postscript = JSON.parse(userInfo).preferences.postscript

    return postscript;
  }

  // public setUser(token: string, userInfo: any, expiresAt: number): void {
  public setUser(userData: UserData): void {
    // using local storage is only required
    // when not using cookies to store the JWT
    this.setToken(userData.token);
    this.setUserInfo(userData.userInfo);
    this.setExpiresAt(userData.expiresAt);
  }

  public register(user: NewUser): Observable<any> {
    return this.http.post(`${this.baseUrl}/register`, { ...user });
  }

  public logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('expiresAt');
    this.router.navigate(['anmelden']);
  }

  public isAdmin(): boolean {
    const userInfo = this.getUserInfo();
    if (!userInfo) {
      return false;
    }
    return userInfo.role === 'admin';
  }

  isLeaderCheck(): boolean {
    const userInfo = this.getUserInfo();
    if (!userInfo) {
      return false;
    }
    return userInfo.isLeader === true;
  }

  public hasApiKey(): boolean {
    const userInfo = this.getUserInfo();
    if (!userInfo.apiKey) {
      return false;
    }
    return true;
  }

  public userHasRole(expectedRole: string): boolean {
    const userInfo = this.getUserInfo();
    return expectedRole === userInfo.role;
  }
}
