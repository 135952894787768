import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './../auth/auth.service';
import { InfoMail } from './mail.model';

@Injectable({
  providedIn: 'root',
})
export class MailService {

  constructor(
    public authService: AuthService,
    public http: HttpClient
  ) { }

  createMail(infoMail: InfoMail): Observable<any> {
    return this.http.post<any>('/api/mails/sendmail', infoMail);
  }

  // private errorHandler(error: HttpErrorResponse): Observable<any> {
  //   console.error('Fehler aufgetreten!');
  //   return throwError(error);
  // }
}
