import { Component } from '@angular/core';

@Component({
  selector: 'fnsr-finsuro-mail-logo',
  templateUrl: './finsuro-mail-logo.component.html',
  styleUrls: ['./finsuro-mail-logo.component.css']
})
export class FinsuroMailLogoComponent {

  constructor() { }

}
