// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-error {
  padding: 5px 10px;
  background-color: hsl(0, 100%, 64%);
}

.input-error p {
  color: #fff;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/ui-design-library/input-error/input-error.component.scss","webpack://./../../finsuro.mail%20(IVB%20Meeting%20Edition)/Frontend/src/app/ui-design-library/input-error/input-error.component.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,mCAAA;ACDF;;ADIA;EACE,WAAA;EACA,eAAA;ACDF","sourcesContent":["@import '../../../variables';\n\n.input-error {\n  padding: 5px 10px;\n  background-color: $red;\n}\n\n.input-error p {\n  color: #fff;\n  font-size: 12px;\n}\n",".input-error {\n  padding: 5px 10px;\n  background-color: hsl(0, 100%, 64%);\n}\n\n.input-error p {\n  color: #fff;\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
