import { Directive } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from "@angular/forms";

export function zipValidator(control: AbstractControl): ValidationErrors | null {
    const re = /^[0-9]{5}$/i;
    if (!control.value || control.value === '' || re.test(control.value)) {
        return null;
    } else {
        return { invalidZip: true };
    }
}

export function emailValidator(control: AbstractControl): ValidationErrors | null {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
    if (!control.value || control.value === '' || re.test(control.value)) {
        return null;
    } else {
        return { emailInvalid: true };
    }
}

export function barmeniaEmailValidator(control: AbstractControl): ValidationErrors | null {
  const re = /^[A-Za-z0-9._%+-]+@barmenia.de$/i;
  if (!control.value || control.value === '' || re.test(control.value)) {
    return null;
  } else {
    return { noBarmeniaDomain: true};
  }
}

export function allowedDomainValidator(control: AbstractControl): ValidationErrors | null {
    const re = /^[A-Za-z0-9._%+-]+@(?:barmenia|hansemerkur|bgrg|finsuro).de$/i;
    if (!control.value || control.value === '' || re.test(control.value)) {
        return null;
    } else {
        return { noAllowedDomain: true };
    }
}

export function printPieceNumberValidator(control: AbstractControl): ValidationErrors | null {
    const re = /^[BKLSWZ]{1,2}[0-9]{4}[a-z]{0,1}$/i;
    if (!control.value || control.value === '' || re.test(control.value)) {
        return null;
    } else {
        return { invalidPrintPieceNumber: true };
    }
}

export function intermediaryNumberValidator(control: AbstractControl): ValidationErrors | null {
    const re = /^[0-9]{8}$/i;
    if (!control.value || control.value === '' || re.test(control.value)) {
        return null;
    } else {
        return { intermediaryNumberInvalid: true };
    }
}

export function phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
    const re = /[\+49]\d{13}$/i;
    if (!control.value || control.value === '' || re.test(control.value)) {
        return null;
    } else {
        return { invalidPhoneNumber: true };
    }
}

@Directive({
    selector: '[CustomValidators]',
    providers: [
        { provide: NG_VALIDATORS, useValue: zipValidator, multi: true },
        { provide: NG_VALIDATORS, useValue: emailValidator, multi: true },
        { provide: NG_VALIDATORS, useValue: barmeniaEmailValidator, multi: true },
        { provide: NG_VALIDATORS, useValue: allowedDomainValidator, multi: true },
        { provide: NG_VALIDATORS, useValue: printPieceNumberValidator, multi: true },
        { provide: NG_VALIDATORS, useValue: intermediaryNumberValidator, multi: true },
        { provide: NG_VALIDATORS, useValue: phoneNumberValidator, multi: true }
    ]
})
export class CustomValidatorWithFunctionDirective {
}
