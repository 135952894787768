import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fnsr-progress',
  templateUrl: './progress.component.html'
})
export class ProgressComponent implements OnInit {
  @Input() name = '';

  constructor() { }

  ngOnInit(): void {
  }

}
