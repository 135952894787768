import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface MediaElement {
  UrlThumbnail: string;
  Art: string;
  Kategorie: string;
  Titel: string;
  Url: string;
  modId: string;
}

@Component({
  selector: 'fnsr-mail-media-list',
  templateUrl: './mail-media-list.component.html',
  styleUrls: ['./mail-media-list.component.scss'],
})
export class MailMediaListComponent implements OnInit {
  @Input() id = '';

  displayedColumns: string[] = ['UrlThumbnail', 'Art', 'Kategorie', 'Titel'];
  dataSource: MediaElement[] = [];

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.http.post<MediaElement[]>(`/api/mails/listmedia/${this.id}`, {})
      .subscribe(
        (mediaElements) => {
          this.dataSource = mediaElements;
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
