import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { retry, map, catchError } from 'rxjs/operators';
import { User } from './user.model';
import { UserLog } from './user-log.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public http: HttpClient) { }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(`/api/users`)
      .pipe(
        retry(3),
        map(
          result => result
        ),
        catchError(this.errorHandler)
      );
  }

  // TODO: Übearbeiten, um ggf. als user$ zu funktionieren (vgl. getUsers)
  getUser(id: string): Observable<User> {
    return this.http.get<User>(`/api/users/${id}`)
      .pipe(
        retry(3),
        map(
          result => {
            return result
          }
        ),
        catchError(this.errorHandler)
      );
  }

  getUserLogsByOwnerId(id: string): Observable<UserLog[]> {
    return this.http.get<UserLog[]>(`/api/userlogs/agent?id=${id}`)
      .pipe(
        retry(3),
        map(
          result => {
            return result
          }
        ),
        catchError(this.errorHandler)
      );
  }

  extractNameFromEmail(email: string): Observable<any> {
    return this.http.post(`/api/words/extract/namefromemail`, { email })
      .pipe(
        retry(3),
        map(
          result => {
            return result
          }
        ),
        catchError(this.errorHandler)
      );
  }

  buildAgentUrlFromEmail(email: string): Observable<any> {
    return this.http.post(`/api/tools/build/agenturlfromemail`, { email })
      .pipe(
        retry(3),
        map(
          result => {
            return result
          }
        ),
        catchError(this.errorHandler)
      );
  }

  postFindUsersBySearch(query: string): Observable<User[]> {
    return this.http.get<User[]>(`/api/users/search?query=${query}`)
      .pipe(
        retry(3),
        map(
          result => result
        ),
        catchError(this.errorHandler)
      );
  }

  postUser(user: User): Observable<any> {
    return this.http.post('/api/users', { data: user });
  }

  updateUser(id: string, user: User): Observable<any> {
    return this.http.post(`/api/users/${id}/edit`, { user });
  }

  deleteUser(id: string): Observable<any> {
    return this.http.delete(`/api/users/${id}`);
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    console.error('Fehler aufgetreten!');
    return throwError(error);
  }
}
