// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
input, select {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #dbdbdb;
  border-radius: 0;
}

input:focus, select:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
}

/* Workaround für (graue) Textfarbe, da select und option keinen placeholder kennen */
/* Wert stammt aus ::webkit-input_placeholder */
select:required:invalid {
  color: rgba(54, 54, 54, 0.3);
}`, "",{"version":3,"sources":["webpack://./../../finsuro.mail%20(IVB%20Meeting%20Edition)/Frontend/src/app/mail/mail-form/mail-form.component.scss","webpack://./src/app/mail/mail-form/mail-form.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;ACAhB;EACE,YAAA;EACA,gBAAA;EACA,gCAAA;EACA,gBAAA;ADEF;;ACCA;EACE,gBAAA;EACA,wBAAA;ADEF;;ACCA,qFAAA;AACA,+CAAA;AACA;EACE,4BAAA;ADEF","sourcesContent":["@charset \"UTF-8\";\ninput, select {\n  border: none;\n  box-shadow: none;\n  border-bottom: 1px solid #dbdbdb;\n  border-radius: 0;\n}\n\ninput:focus, select:focus {\n  box-shadow: none;\n  -webkit-box-shadow: none;\n}\n\n/* Workaround für (graue) Textfarbe, da select und option keinen placeholder kennen */\n/* Wert stammt aus ::webkit-input_placeholder */\nselect:required:invalid {\n  color: rgba(54, 54, 54, 0.3);\n}","input, select {\n  border: none;\n  box-shadow: none;\n  border-bottom: 1px solid #dbdbdb;\n  border-radius: 0;\n}\n\ninput:focus, select:focus {\n  box-shadow: none;\n  -webkit-box-shadow: none;\n}\n\n/* Workaround für (graue) Textfarbe, da select und option keinen placeholder kennen */\n/* Wert stammt aus ::webkit-input_placeholder */\nselect:required:invalid {\n  color: rgba(54, 54, 54, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
