import { Component, Input, OnInit } from '@angular/core';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

type FaStyle = '' | 'duotone';

@Component({
  selector: 'fnsr-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  cyan = "hsl(198, 100%, 45%)"
  lemongreen = "hsl(72, 100%, 50%)"

  primaryColor = this.cyan
  secondaryColor = this.lemongreen

  @Input() icon: IconProp = 'exclamation'
  @Input() faStyle: FaStyle = ''
  @Input() size: SizeProp = 'lg'
  @Input() colors = [this.primaryColor, this.secondaryColor] // TODO: War vorher [''], ggf. muss es anders gebaut werden
  @Input() stroke = ''
  @Input() color = ''
  @Input() swCol: boolean = false // swap color from primaryColor to secondaryColor and vice versa
  @Input() swOp: boolean = true // swapOpacity
  @Input() spin: boolean = false
  @Input() class = ''

  constructor() { }

  ngOnInit(): void {
    if (this.swCol) {
      this.primaryColor = this.lemongreen
      this.secondaryColor = this.cyan
    }

    if (this.colors) {
      this.primaryColor = this.colors[0]
      this.secondaryColor = this.colors[1]
    }
  }

}
